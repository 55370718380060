/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
window.Vue = require('vue').default;
window.Admin = '1383486';
import vueVimeoPlayer from 'vue-vimeo-player'
import VeeValidate from 'vee-validate';
import '@fortawesome/fontawesome-free/js/all.js';


Vue.use(vueVimeoPlayer)
Vue.use(VeeValidate);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
Vue.component('volatility-index-show', () => import('./components/volatility-index/Show.vue'));
Vue.component('sp-tp-optimizer-index-show', () => import('./components/sp-tp-optimizer/Show.vue'));
Vue.component('winning-rate-index-show', () => import('./components/winning-rate/Show.vue'));
Vue.component('strategy-index', () => import('./components/strategy/Index.vue'));
Vue.component('strategies-show', () => import('./components/strategy/Show.vue'));
Vue.component('strategy-trades', () => import('./components/strategy/StrategyTrades.vue'));
Vue.component('prediction-index-show', () => import('./components/prediction/Show.vue'));
Vue.component('api-management-index', () => import('./components/api-management/Index.vue'));
Vue.component('api-management-create', () => import('./components/api-management/Create.vue'));
Vue.component('alert-info', () => import('./components/alerts/Alert-info.vue'));
Vue.component('trade-allow', () => import('./components/trade-allow/Index'));
Vue.component('place-order', () => import('./components/place-order/Index'));
Vue.component('telegram-group', () => import('./components/telegram-group/Show.vue'));
Vue.component('tickers', () => import('./components/tickers/Show.vue'));
Vue.component('closed-profit-and-loss', () => import('./components/closed-profit-and-loss/Show.vue'));
Vue.component('closed-profit-and-loss-websocket', () => import('./components/closed-profit-and-loss/Show-websocket.vue'));
Vue.component('telegram-mod-show', () => import('./components/telegram-mod/Show.vue'));
Vue.component('poll-show', () => import('./components/poll/Show.vue'));
Vue.component('poll-create', () => import('./components/poll/Create.vue'));
Vue.component('poll-edit', () => import('./components/poll/Edit.vue'));
Vue.component('vote-show', () => import('./components/vote/Show.vue'));
Vue.component('real-time-vote', () => import('./components/real-time-vote/Show.vue'));
Vue.component('trading-tutorial-detail', () => import('./components/trading-tutorial/Detail.vue'));
Vue.component('videos-upload', () => import('./components/video/Show.vue'));
Vue.component('types', () => import('./components/type/Show.vue'));
Vue.component('live-stream', () => import('./components/livestream/Livestream.vue'));
Vue.component('feedbacks', () => import('./components/feedbacks/Index.vue'));
Vue.component('verify', () => import('./components/verify/Index.vue'));
Vue.component('get-access', () => import('./components/get-access/Index.vue'));
Vue.component('uid-access', () => import('./components/verify/UidAccess.vue'));
Vue.component('bybit-account-not-active', () => import('./components/verify/BybitAccountNotActive.vue'));
Vue.component('bybit-account-not-linked', () => import('./components/verify/BybitAccountNotLinked.vue'));
Vue.component('maintenance', () => import('./components/verify/Maintenance.vue'));
Vue.component('bullish-bearish-show', () => import('./components/bullish-bearish/Show.vue'));
Vue.component('bullish-bearish-create', () => import('./components/bullish-bearish/Create.vue'));
Vue.component('bullish-bearish-show-list', () => import('./components/bullish-bearish/Show-list.vue'));
Vue.component('coin-list', () => import('./components/coins/Show.vue'));
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


const app = new Vue({
    el: '#app'
});

