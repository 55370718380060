window._ = require('lodash');
try {
    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */




// import Echo from "laravel-echo"
// window.Pusher = require('pusher-js');
//
// console.log(window.MIX_PUSHER_APP_KEY, 'KEY', window.MIX_PUSHER_APP_CLUSTER, 'CLUSTER')
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: window.MIX_PUSHER_APP_KEY,
//     wsHost: window.location.hostname,
//     wsPort: 6001,
//     forceTLS: false,
//     disableStats: true,
//     cluster: window.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true,
// });
